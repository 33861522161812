@import "~@/assets/scss/global.scss";















































































































































































































































































































































































































































































































































































































































































































































































































































































::v-deep {
  .el-textarea__inner {
    overflow: hidden !important;
  }

  .preview_img {
    cursor: pointer;
  }
}

.botlst-box {
  border: 1px solid lightgray;
  text-align: center;
  background: #eceae9 !important;

  ::v-deep {
    .el-main {
      overflow-y: auto;
    }
  }


  .bot-box {
    cursor: pointer;
    margin: 10px 5px;

    .bot-avatar {
      width: 35px;
      height: 35px;
      border-radius: 50%;
      border: 1px solid lightgray;
    }

    .bot-name {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box !important;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      word-break: break-all;
    }
  }

  .active {
    .bot-avatar {
      border: 1px solid #409eff;
    }

    .bot-name {
      color: #409eff;
    }
  }

  .bot-box:hover {
    .bot-avatar {
      border: 1px solid #409eff;
    }

    .bot-name {
      color: #409eff;
    }
  }
}


.senders-box {
  background: #f6f6f6 !important;
  border: 1px solid lightgray;

  ::v-deep {
    .el-main {
      overflow-y: auto;
    }
  }

  .sender-box {
    cursor: pointer;
    box-sizing: border-box;
    position: relative;
    height: 55px;
    line-height: 55px;
    padding: 10px 5px;
    margin-bottom: 2px;

    .sender-avatar {
      box-sizing: border-box;

      color: #0075e3;
      background: #e2edf7;

      width: 35px;
      height: 35px;
      line-height: 35px;
      border-radius: 50%;
      border: 1px solid #d9ecff;

      text-align: center;
    }

    .sender-name {
      position: absolute;
      top: 0;
      left: 45px;
      width: 85px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 12px;
    }

    .sender-num {
      position: absolute;
      top: 20px;
      left: 120px;
      background: #F56C6C;
      border: 1px solid #F56C6C;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      color: snow;
      font-size: 12px;
      justify-content: center;
      align-items: center;
      text-align: center;
      display: flex;
    }
  }

  .active {
    background: #409eff;

    .sender-avatar {
      background: white;
    }

    .sender-name {
      color: white;
    }
  }

  .sender-box:hover {
    background: #409eff;

    .sender-avatar {
      background: white;
    }

    .sender-name {
      color: white;
    }
  }
}

.message-box {
  box-sizing: border-box;
  border: 1px solid lightgray;
  height: 100%;
  background: #f1f1f1 !important;
  position: relative;

  ::v-deep {
    .el-main {
      overflow: auto;
    }
  }

  &-title {
    line-height: 40px;
    text-align: center;
    background: #f6f6f6;
    font-size: 18px;
    font-weight: 650;
  }

  .sender-remark-input {
    ::v-deep {
      .el-input__inner {
        border: 0px;
        outline: none;
        color: #2789E7;
        background-color: #C3E1FF;
      }
    }

    ::placeholder {
      color: #90C4F7;
    }

    ::-webkit-input-placeholder {
      /* Edge */
      color: #90C4F7;
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #90C4F7;
    }
  }

  &-top {
    text-align: center;
    margin: 10px 0;
    color: lightgray;
  }

  &-filter {
    position: absolute;
    right: 15px;
    top: 80px;
    z-index: 1;
  }

  &-new {
    text-align: center;
    margin: 10px 0;
    color: #b4d5ff;
  }

  &-time {
    text-align: center;
    margin: 10px 0;
    color: lightgray;
  }

  &-content {
    overflow: auto;
    box-sizing: border-box;

    .message-box-footer {
      position: absolute;
      bottom: 0;
      width: 498px;
    }

    .message-content-main {
      background-color: inherit;

      &>aside,
      &>main {
        border: 0;

        .el-avatar {

          color: #409eff;
          background: #ecf5ff;
          border: 1px solid #d9ecff;

          text-align: center;

          &.left {
            background: #409eff;
            color: white;
            float: left;
          }

          &.right {
            float: right;
            border: 1px solid #409eff;
          }
        }

        .el-card {
          border-radius: 5px;

          button {
            padding: 0;
            padding-top: 5px;
          }

          ::v-deep {
            .el-card__header {
              padding: 0;
              margin-right: 10px;
              text-align: right;
              border-bottom: 0;
              min-height: 10px;
            }

            .el-card__body {
              padding: 5px 10px;
            }
          }

          &.proxy {
            position: relative;

            &::before {
              content: "代";
              height: 20px;
              width: 20px;
              position: absolute;
              box-sizing: border-box;
              top: 0;
              left: 0;
              color: #ffffff;
              background: #6ab319;
              border-bottom-right-radius: 10px;
              padding-left: 2px;
              font-size: 14px;
              z-index: 50;
            }
          }
        }

      }
    }
  }

  &-refresh {
    cursor: pointer;
    position: absolute;
    bottom: 150px;
    right: 15px;
    color: #79CA4F;
    width: 90px;
    height: 35px;
    line-height: 35px;
    margin: 5px;
    padding: 0 5px 0 25px;
    border-radius: 8px;
    box-shadow: 2px 2px 5px 2px #CACACA;
    background-color: #FFFFFF;
    text-align: center;

    &-arrow {
      width: 25px;
      height: 25px;
      position: absolute;
      top: 4px;
      left: 5px;
    }
  }
}

.content-box {
  border: 0 !important;

  .el-main {
    .stall-trade-table {
      height: 100%;
    }
  }
}

.qq-face {
  width: 400px;
  height: 200px;
  overflow-y: scroll;

  &>img:hover {
    background-color: #e8e8e8;
    cursor: pointer;
  }
}

.operate {
  padding: 3px;

  button {
    padding: 8px;
    font-size: 14px;
  }

  img,
  i {
    margin-left: 10px;
    font-size: 25px;
    vertical-align: middle;
    cursor: pointer;
  }
}

.pic-upload {
  text-align: center;

  ::v-deep {
    .el-upload-dragger {
      width: 178px;
      height: 178px;

      .el-icon-plus {
        font-size: 28px;
        width: 100%;
        height: 100%;
        line-height: 178px;
      }

      .avatar {
        width: 178px;
        height: 178px;
      }
    }
  }
}
